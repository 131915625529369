@import "../../themes/generated/variables.base.scss";

.single-card {
  height: 100%;
  .dx-card {
    //width: 330px;
    height: 100%;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }
    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}


.image-layout{
  width:100%;
  height:100%;
}

.scroll-option{
  overflow: hidden;
}

.side-image{
  position: absolute;
  width:100px;
  height:100px;
  left: 0;
  bottom: 0;
  z-index: 1; /* 메인 이미지는 위에 보이도록 설정 */
}


.float-right{
  float: right;
}