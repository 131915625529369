.margin-option{
  margin: 0 30px 0px 0px !important;
}

.button-padding{
  padding: 0 0% 0 10%;
}

.field-width{
  width: 100% !important;
}