.label-option{
  width:110px
}

.padding-10{
  padding: 10px 0 0 0;
}

.profile-paddings{
 padding: 30px 60px 30px 60px;
}

.dx-field-label-50{
  width: 50px;
}