.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  display: inline-block;
  margin-right: 40px;
}

.option > .dx-selectbox,
.option > .label {
  display: inline-block;
  vertical-align: middle;
}

.option > .label {
  margin-right: 10px;
}

.container-custom{
  width: 100%;
  text-align: justify !important;
}